<template>
  <div class="car-container">
    <ContentTitle>{{ tableTitle }}</ContentTitle>
    <Wfilter
      :filterConfig="filterConfig"
      @openMonthlyCarDialog="openMonthlyCarDialog"
      @handleSearch="handleSearch"
      @changeProject="changeProject"
      @changeParking="changeParking"
      @handleSearchTime="handleSearchTime"
      @print="handlePrint"
      @export="handleExport"
    ></Wfilter>
    <Wtable
      :columns="columns"
      :pagination="pagination"
      :tableData="tableData"
      @currentChange="currentChange"
      @info="openDetailDialog"
      @recharge="openMonthlyCarDialog"
      @update="openMonthlyCarDialog"
      @cancel="openCancelDialog"
      @sizeChange="sizeChange"
    >
      <template #carPark="{ row }">
        <span>{{ row.carPark.name }} </span>
      </template>
      <template #carType="{ row }">
        <span>{{ row.carType.name }} </span>
      </template>
    </Wtable>
    <Wdialog
      class="detail-dialog"
      ref="detailDialog"
      title="车辆详情"
      width="50%"
      :showAction="false"
    >
      <el-descriptions :column="3" class="detail-info" border>
        <el-descriptions-item
          v-for="(el, index) in detailInfo"
          :key="index"
          :label="el.label"
          >{{ el.value }}</el-descriptions-item
        >
      </el-descriptions></Wdialog
    >
    <Wdialog
      ref="monthlyCarDialog"
      :title="['', '新增包月车', '续费包月车', '编辑包月车'][monthlyCarType]"
      width="50%"
      @wConfirm="handleSure"
    >
      <el-form
        ref="monthlyCarFormRef"
        :model="monthlyCarFormData"
        :rules="monthlyCarFormRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="project_id" label="所属项目">
              <el-select
                v-model="monthlyCarFormData.project_id"
                :disabled="monthlyCarType == 1 ? false : true"
                placeholder="请选择所属项目"
                @change="changeSelectProject"
              >
                <el-option
                  v-for="item in projectOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item prop="car_park_id" label="停车场">
              <el-select
                :disabled="
                  monthlyCarType == 1 && monthlyCarFormData.project_id
                    ? false
                    : true
                "
                v-model="monthlyCarFormData.car_park_id"
                placeholder="请选择停车场"
              >
                <el-option
                  v-for="item in parkingOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="owner_name" label="联系人员">
              <el-input
                v-model="monthlyCarFormData.owner_name"
                autocomplete="off"
                :disabled="monthlyCarType == 2 ? true : false"
                placeholder="请输入联系人员"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="owner_tel" label="联系电话">
              <el-input
                v-model="monthlyCarFormData.owner_tel"
                autocomplete="off"
                :disabled="monthlyCarType == 2 ? true : false"
                placeholder="请输入联系电话"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="carnonmotor_type_id" label="车辆类型">
              <el-select
                v-model="monthlyCarFormData.carnonmotor_type_id"
                placeholder="请选择车辆类型"
                clearable
                :disabled="monthlyCarType == 2 ? true : false"
              >
                <el-option
                  v-for="item in parkingTypeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8" v-if="monthlyCarType != 3">
            <el-form-item prop="month" label="缴费日期">
              <el-date-picker
                v-model="monthlyCarFormData.month"
                type="date"
                placeholder="请选择缴费日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="monthlyCarType == 3">
            <el-form-item prop="trafficMonth" label="通行时间">
              <el-date-picker
                disabled
                v-model="monthlyCarFormData.trafficMonth"
                placeholder="请选择通行时间"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="monthlyCarType != 3">
            <el-form-item prop="date" label="时间范围">
              <el-date-picker
                v-model="monthlyCarFormData.date"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="monthlyCarType != 3">
            <el-form-item prop="pay_type_id" label="收款方式">
              <el-select
                v-model="monthlyCarFormData.pay_type_id"
                placeholder="请选择收款方式"
              >
                <el-option
                  v-for="item in chargeWayOptions"
                  :key="item.level"
                  :label="item.value"
                  :value="item.level"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item prop="code" label="IC卡号">
              <el-input
                v-model="monthlyCarFormData.code"
                autocomplete="off"
                placeholder="请输入IC卡号"
                clearable
                :disabled="monthlyCarFormData.id ? true : false"
              /> </el-form-item
          ></el-col>
          <el-col :span="8" v-if="monthlyCarType != 3">
            <el-form-item prop="order_money" label="缴费金额">
              <el-input
                type="number"
                min="0"
                v-model="monthlyCarFormData.order_money"
                autocomplete="off"
                placeholder="请输入缴费金额"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="monthlyCarType == 1">
            <el-form-item prop="money" label="押金金额">
              <el-input
                type="number"
                min="0"
                v-model="monthlyCarFormData.money"
                autocomplete="off"
                placeholder="请输入押金金额"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="summation" v-if="!monthlyCarFormData.id">
        费用合计：<span>{{ totalMonney }}元</span>
      </div>
    </Wdialog>
    <wConfirmDialog
      ref="cancelDialog"
      @wConfirm="handleCancel"
      title="确定删除该条车辆信息吗？"
    ></wConfirmDialog>
  </div>
</template>
<script>
import { ref, onMounted, reactive, watch, computed, onUnmounted } from "vue";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
import {
  print,
  exportExcel,
  getBtnAuth,
  getTopBtnAuth,
} from "@/utils/common.js";
import Wfilter from "@/components/wFilter/wFilter.vue";
import Wtable from "@/components/wTable/wTable.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import wConfirmDialog from "@/components/wConfirmDialog/wConfirmDialog.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import API from "@/plugins/api.js";
import dayjs from "dayjs";
import config from "./car.json";
export default {
  components: {
    Wfilter,
    Wtable,
    Wdialog,
    wConfirmDialog,
    ContentTitle,
  },
  setup() {
    const filterConfig = reactive(config.filterConfig);
    const columns = reactive(config.tableConfig.list);
    const defaultBtns = reactive(config.tableConfig.btns);
    const pagination = config.tableConfig.pagination;
    const tableData = ref([]);
    const page = ref("");
    const keyword = ref("");
    const projectId = ref("");
    const parkingId = ref("");
    const dateArr = ref([]);
    const tableTitle = ref("车辆列表");
    const store = useStore();
    // 获取权限按钮
    const rightBtn = computed(() => store.state.saasConfig.rightBtn); //右侧页面的所有权限信息
    columns[columns.length - 1].btns = getBtnAuth(rightBtn.value, defaultBtns); // 获取有权限查看或操作的表格列
    filterConfig.btns = getTopBtnAuth(rightBtn.value, filterConfig.btns);

    function fetchData(parmas) {
      let lastParmas = {
        ...parmas,
        keyword: keyword.value,
        project_id: projectId.value,
        page: page.value,
        carpark_id: parkingId.value,
        limit: pagination.pageSize,
        time: dateArr.value,
      };
      API.getCar(lastParmas).then((res) => {
        pagination.total = res.total;
        tableData.value = res.data;
        if (tableData.value.length) {
          tableData.value.forEach((el) => {
            el.desc = el.desc ? el.desc : "";
            el.carParkName =
              el.carPark && el.carPark.name ? el.carPark.name : "";
            el.carTypeName =
              el.carType && el.carType.name ? el.carType.name : "";
          });
        }
      });
    }
    const monthlyCarFormData = ref({
      project_id: "",
      car_park_id: "",
      pay_type_id: "",
      owner_name: "",
      owner_tel: "",
      carnonmotor_type_id: "",
      month: "",
      date: [],
      code: "",
      money: "",
      order_money: "",
      trafficMonth: [],
    });
    const totalMonney = computed(() => {
      return (
        +monthlyCarFormData.value.money + +monthlyCarFormData.value.order_money
      );
    });
    const projectOptions = ref([]);
    const parkingOptions = ref([]);
    const parkingTypeOptions = ref([]);
    const chargeWayOptions = ref([]);
    onMounted(() => {
      API.getProjectOptions({}).then((res) => {
        if (res.length) {
          projectOptions.value = res;
          filterConfig.selects[0].mapData = res;
          filterConfig.selects[0].value = res[0].id;
          projectId.value = res[0].id;
        }
      });
      API.getCarTypeOptions().then((res) => {
        parkingTypeOptions.value = res;
      });
      API.getChargeWayOptions({}).then((res) => {
        if (res.length) {
          chargeWayOptions.value = res;
        }
      });
    });
    watch(
      () => monthlyCarFormData.value.project_id,
      (v) => {
        if (v) {
          API.getParkingOptions({ project_id: v }).then((res) => {
            parkingOptions.value = res;
          });
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    function changeSelectProject() {
      monthlyCarFormData.value.car_park_id = "";
    }
    watch(
      () => projectId.value,
      (v) => {
        if (v) {
          parkingId.value = "";
          filterConfig.selects[1].disabled = false;
          filterConfig.selects[1].value = "";
          API.getParkingOptions({ project_id: v }).then((res) => {
            filterConfig.selects[1].mapData = res;
          });
          fetchData();
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    const currentChange = (v) => {
      page.value = v;
      fetchData();
    };
    const sizeChange = (v) => {
      pagination.pageSize = v;
      fetchData();
    };
    const changeProject = (pro) => {
      projectId.value = pro.value;
    };
    function changeParking(data) {
      parkingId.value = data.value;
      fetchData();
    }
    const handleSearch = (kwd) => {
      keyword.value = kwd;
      fetchData();
    };
    const handleSearchTime = (data) => {
      dateArr.value = data;
      fetchData();
    };

    const commonRules = {
      project_id: [
        { required: true, message: "请选择所属项目", trigger: "change" },
      ],
      car_park_id: [
        { required: true, message: "请选择停车场", trigger: "change" },
      ],
      owner_name: [
        { required: true, message: "请输入联系人员", trigger: "blur" },
      ],
      owner_tel: [
        { required: true, message: "请输入联系电话", trigger: "blur" },
      ],
      carnonmotor_type_id: [
        { required: true, message: "请选择车辆类型", trigger: "blur" },
      ],
      code: [{ required: true, message: "请输入IC卡号", trigger: "blur" }],
    };
    const monthlyCarFormRules = ref({});
    const monthlyCarDialog = ref(null);
    const monthlyCarFormRef = ref(null);
    const monthlyCarType = ref(null); // 1新增 2续费  3编辑
    function openMonthlyCarDialog(data, type) {
      if (data && data.row) {
        if (type === "续费") {
          monthlyCarType.value = 2;
          monthlyCarFormRules.value = {
            ...commonRules,
            month: [
              { required: true, message: "请选择缴费日期", trigger: "blur" },
            ],

            order_money: [
              { required: true, message: "请输入缴费金额", trigger: "blur" },
            ],
            date: [
              { required: true, message: "请选择时间范围", trigger: "change" },
            ],
            pay_type_id: [
              { required: true, message: "请选择收款方式", trigger: "change" },
            ],
          };
        } else {
          monthlyCarType.value = 3;
          monthlyCarFormRules.value = {
            ...commonRules,
            trafficMonth: [
              { required: true, message: "请选择通行日期", trigger: "change" },
            ],
          };
        }

        const rowData = JSON.parse(JSON.stringify(data.row));
        rowData["month"] = "";
        rowData["trafficMonth"] = [rowData.starttime, rowData.endtime];
        monthlyCarFormData.value = rowData;
      } else {
        // 新增
        monthlyCarType.value = 1;
        monthlyCarFormRules.value = {
          ...commonRules,
          money: [
            { required: true, message: "请输入押金金额", trigger: "blur" },
          ],
          month: [
            { required: true, message: "请选择缴费日期", trigger: "blur" },
          ],

          order_money: [
            { required: true, message: "请输入缴费金额", trigger: "blur" },
          ],
          date: [
            { required: true, message: "请选择时间范围", trigger: "change" },
          ],
          pay_type_id: [
            { required: true, message: "请选择收款方式", trigger: "change" },
          ],
        };
        monthlyCarFormData.value = {
          project_id: "",
          car_park_id: "",
          pay_type_id: "",
          owner_name: "",
          owner_tel: "",
          carnonmotor_type_id: "",
          month: "",
          date: [],
          code: "",
          money: "",
          order_money: "",
          trafficMonth: [],
        };
      }

      monthlyCarDialog.value.show();
    }
    function handleSure() {
      if (monthlyCarFormRef.value) {
        monthlyCarFormRef.value.validate((valid) => {
          const parmas = JSON.parse(JSON.stringify(monthlyCarFormData.value));
          let lastParmas = {
            car_park_id: parmas.car_park_id,
          };
          if (monthlyCarType.value != 3) {
            lastParmas["month"] = dayjs(parmas["month"]).format("YYYYMMDD");
            lastParmas["order_money"] = parmas.order_money;

            if (
              dayjs(parmas["date"][0]).unix() > dayjs(parmas["date"][1]).unix()
            ) {
              lastParmas["endtime"] = dayjs(parmas["date"][0]).unix();
              lastParmas["starttime"] = dayjs(parmas["date"][1]).unix();
            } else {
              lastParmas["endtime"] = dayjs(parmas["date"][1]).unix();
              lastParmas["starttime"] = dayjs(parmas["date"][0]).unix();
            }
          }
          if (valid) {
            monthlyCarDialog.value.showLoading();
            if (parmas.id) {
              //   monthlyCarType 1新增 2续费  3编辑
              lastParmas = {
                ...lastParmas,
                id: parmas.id,
                pay_type_id: parmas.pay_type_id,
              };
              if (monthlyCarType.value == 2) {
                API.renewMonthlyCar(lastParmas)
                  .then(() => {
                    ElMessage.success({
                      message: "操作成功",
                    });
                    monthlyCarDialog.value.close();
                    fetchData();
                  })
                  .catch(() => {
                    monthlyCarDialog.value.closeLoading();
                  });
              } else {
                lastParmas = {
                  id: parmas.id,
                  carnonmotor_type_id: parmas.carnonmotor_type_id,
                  owner_name: parmas.owner_name,
                  owner_tel: parmas.owner_tel,
                };

                API.editMonthlyCar(lastParmas)
                  .then(() => {
                    ElMessage.success({
                      message: "操作成功",
                    });
                    monthlyCarDialog.value.close();
                    fetchData();
                  })
                  .catch(() => {
                    monthlyCarDialog.value.closeLoading();
                  });
              }
            } else {
              lastParmas = {
                ...lastParmas,
                project_id: parmas.project_id,
                carnonmotor_type_id: parmas.carnonmotor_type_id,
                owner_name: parmas.owner_name,
                owner_tel: parmas.owner_tel,
                code: parmas.code,
                money: parmas.money,
                pay_type_id: parmas.pay_type_id,
              };
              //新增
              API.addMonthlyCar(lastParmas)
                .then(() => {
                  ElMessage.success({
                    message: "操作成功",
                  });
                  monthlyCarDialog.value.close();
                  fetchData();
                })
                .catch(() => {
                  monthlyCarDialog.value.closeLoading();
                });
            }
          }
        });
      }
    }
    const detailDialog = ref(null);
    const detailInfo = ref({});
    function openDetailDialog(data) {
      API.getCarDetail({ id: data.row.id }).then((res) => {
        detailInfo.value = [
          {
            label: "所属项目",
            value: res.project ? res.project.name : "暂无",
          },
          {
            label: "联系人员",
            value: res.owner_name ? res.owner_name : "暂无",
          },
          {
            label: "IC卡号",
            value: res.icCard ? res.icCard.code : "暂无",
          },
          {
            label: "所属停车场",
            value: res.carPark ? res.carPark.name : "暂无",
          },
          {
            label: "联系电话",
            value: res.owner_tel ? res.owner_tel : "暂无",
          },
          {
            label: "押金金额",
            value: res.icCard ? res.icCard.money : "暂无",
          },
          {
            label: "车辆类型",
            value: res.carType ? res.carType.name : "暂无",
          },
          {
            label: "收款方式",
            value: res.pay_way ? res.pay_way : "暂无",
          },
          {
            label: "有效期限",
            value: res.starttime + "-" + res.endtime,
          },
        ];
      });

      detailDialog.value.show();
    }
    const cancelDialog = ref(null);
    const deleteId = ref("");
    function openCancelDialog(data) {
      deleteId.value = data.row.id;
      cancelDialog.value.show();
    }
    function handleCancel() {
      API.deleteMonthlyCar({
        id: deleteId.value,
      }).then(() => {
        ElMessage.success({
          message: "操作成功",
        });
        cancelDialog.value.close();
        fetchData();
      });
    }
    const propertiesConfig = ref([
      { field: "carParkName", displayName: "车场名称" },
      { field: "owner_name", displayName: "驾驶员" },
      { field: "owner_tel", displayName: "联系电话" },
      { field: "carTypeName", displayName: "车辆类型" },
      { field: "endtime", displayName: "有效日期" },
      { field: "status_text", displayName: "状态" },
      { field: "desc", displayName: "备注" },
    ]);
    function handlePrint() {
      print(tableTitle.value, tableData.value, propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel(tableTitle.value, tableData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    onUnmounted(() => {
      filterConfig.selects[1].value = "";
    });
    return {
      tableData,
      columns,
      pagination,
      currentChange,
      openMonthlyCarDialog,
      filterConfig,
      changeProject,
      handleSearch,
      changeParking,
      monthlyCarDialog,
      monthlyCarFormRef,
      handleSure,
      monthlyCarFormRules,
      monthlyCarFormData,
      parkingOptions,
      projectOptions,
      parkingTypeOptions,
      detailDialog,
      openDetailDialog,
      detailInfo,
      openCancelDialog,
      handleCancel,
      cancelDialog,
      sizeChange,
      handleSearchTime,
      totalMonney,
      changeSelectProject,
      monthlyCarType,
      handlePrint,
      handleExport,
      tableTitle,
      chargeWayOptions,
    };
  },
};
</script>

<style lang='scss'>
.car-container {
  .header-action {
    .el-input__inner {
      background-color: var(--search-bg-color) !important;
    }
    ::-webkit-input-placeholder {
      color: var(--theme-color);
    }
  }
  .cancel-text {
    line-height: 80px;
  }

  .summation {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 30px;
    > span {
      color: var(--theme-color);
    }
  }
  .detail-dialog {
    .el-dialog__body {
      padding-bottom: 400px;
      .detail-info {
        border-radius: 4px;
        padding: 20px;
        background-color: var(--search-uncheck-bg-color);
        .el-descriptions__body {
          border: none;
          background-color: var(--search-uncheck-bg-color);
          .el-descriptions__label,
          .el-descriptions__content {
            border: 1px solid transparent;
            background-color: var(--search-uncheck-bg-color);
          }
          .el-descriptions__label {
            width: 80px;
            color: var(--text-gray-color);
            font-weight: normal;
          }
          .el-descriptions__cell {
            padding: 0 0 12px 0;
          }
          .el-descriptions__content {
            color: var(--text-color);
          }
        }
      }
    }
  }
}
</style>